import eligibilityService from "./EligibilityService";

export const EligibilityService = {
  eligibilityDetails: eligibilityService.eligibilityDetails,
  triggerManualFileVerification:
    eligibilityService.triggerManualFileVerification,
  manualLoanCalculation: eligibilityService.manualLoanCalculation,
  getSubIndustries: eligibilityService.getSubIndustries,
  getEligibilityList: eligibilityService.getEligibilityList,
  updateEligibilityStatus: eligibilityService.updateEligibilityStatus,
  updateAccountCreationStatus: eligibilityService.updateAccountCreationStatus,
  acceptRejectReceivables: eligibilityService.acceptRejectReceivables,
  updateReceivableAmount: eligibilityService.updateReceivableAmount,

  uploadGstrConfig: eligibilityService.uploadGstrConfig,
  deleteGstrConfig: eligibilityService.deleteGstrConfig,
  getInstanceLoanCalculation: eligibilityService.getInstanceLoanCalculation,
  rejectLoanRequest: eligibilityService.rejectLoanRequest,
  downloadGstFileRequest: eligibilityService.downloadGstFileRequest,
  approveLoanRequest: eligibilityService.approveLoanRequest,
  approveKYCDocsRequest: eligibilityService.approveKYCDocsRequest,
  approveLoanDocsRequest: eligibilityService.approveLoanDocsRequest,
  getLoanCalculation: eligibilityService.getLoanCalculation,
  presignedDocsRequest: eligibilityService.presignedDocsRequest
};

import { takeLatest, takeEvery, put } from "redux-saga/effects";
import { eligibilityActions } from "./actions";
import { Action } from "typescript-fsa";
import { EligibilityService } from "../../services/eligibilityService";

import {
  EligibilityDetails,
  DropdownOptionsResponse,
  LoanCalculationResponse,
} from "../../services/eligibilityService/EligibilityService.type";
import { formatEligibilityDetails } from "./util";
import { LoanCalculationStatus } from "./state";

function* EligibilityDetailsWorker(action: Action<string>) {
  try {
    const response: EligibilityDetails = yield EligibilityService.eligibilityDetails(
      action.payload
    );

    response.status = response.flowStatus;

    const formattedResponse = formatEligibilityDetails(response);
    yield put(
      eligibilityActions.eligibilityDetails.done({
        result: formattedResponse,
        params: action.payload
      })
    );
  } catch (error) {
    yield put(
      eligibilityActions.eligibilityDetails.failed((error as any).message || error)
    );
  }
}

function* SubIndustriesWorker(action: Action<string>) {
  try {
    const response: DropdownOptionsResponse = yield EligibilityService.getSubIndustries(
      action.payload
    );
    yield put(
      eligibilityActions.getSubIndustries.done({
        result: response.result,
        params: action.payload
      })
    );
  } catch (error) {
    yield put(
      eligibilityActions.getSubIndustries.failed((error as any).message || error)
    );
  }
}

function* InstanceLoanCalculation(action: Action<string>) {
  try{
    const result: LoanCalculationResponse = yield EligibilityService.getInstanceLoanCalculation(action.payload);
    if(result && result.status !== LoanCalculationStatus.FAILED){
      yield put(
        eligibilityActions.getInstantLoanCalculations.done({result, params: action.payload})
      )
    }else{
      yield put(
        eligibilityActions.getInstantLoanCalculations.done({
          result,
          params: action.payload
        })
      )
    }
  } catch (error) {
    yield put(
      eligibilityActions.getInstantLoanCalculations.failed((error as any).message || error)
    )
  }
}

function* EligibilityWatcher() {
  yield takeEvery(
    eligibilityActions.eligibilityDetails.started,
    EligibilityDetailsWorker
  );
  yield takeLatest(
    eligibilityActions.getSubIndustries.started,
    SubIndustriesWorker
  );
  yield takeLatest(
    eligibilityActions.getInstantLoanCalculations.started,
    InstanceLoanCalculation
  );
}

export default EligibilityWatcher;

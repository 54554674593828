import { reducerWithInitialState } from "typescript-fsa-reducers";
import { EligibilityState } from "./state";
import { eligibilityActions } from "./actions";

const initialState: EligibilityState = {
  loading: false,
  error: undefined,
  filterStatus: undefined,
  searchText: ""
};

export const EligibilityReducer = reducerWithInitialState(initialState)
  .case(eligibilityActions.eligibilityDetails.started, (state) => ({
    ...state,
    loading: true,
    error: undefined
  }))
  .case(eligibilityActions.eligibilityDetails.done, (state, payload) => ({
    ...state,
    loading: false,
    error: undefined,
    eligibilityDetails: payload.result
  }))
  .case(eligibilityActions.eligibilityDetails.failed, (state, payload) => ({
    ...state,
    loading: false,
    error: payload.error
  }))
  .case(eligibilityActions.updateEligibilityData, (state, payload) => ({
    ...state,
    eligibilityDetails: state.eligibilityDetails
      ? {
          ...state.eligibilityDetails,
          ...payload
        }
      : undefined
  }))
  .case(eligibilityActions.updateStatus, (state, payload) => ({
    ...state,
    eligibilityDetails: state.eligibilityDetails
      ? {
          ...state.eligibilityDetails,
          status: payload.status || state.eligibilityDetails.status,
          subStatus: {
            ...state.eligibilityDetails.subStatus,
            ...payload.subStatus
          }
        }
      : undefined
  }))
  .case(eligibilityActions.updateFilterStatus, (state, payload) => ({
    ...state,
    filterStatus: payload.filterType,
    searchText: payload?.searchText
  }))
  .case(eligibilityActions.getInstantLoanCalculations.started, (state) => ({
    ...state,
    loading: true,
    error: undefined
  }))
  .case(eligibilityActions.getInstantLoanCalculations.done, (state, payload) => ({
    ...state,
    loading: false,
    error: undefined,
    instantCalculations: payload.result
  }))
  .case(eligibilityActions.getInstantLoanCalculations.failed, (state, payload) => ({
    ...state,
    loading: false,
    error: payload.error
  }));

import Moment from "moment";

// All the functions for formatting is placed here.

export const toString = (data: any): string => `${data}`;

export const formatFileSize = (size: number): string => {
  return `${(size / 1000000).toPrecision(2)} MB`;
};

export const formatRange = (progress: number, total: number): string => {
  return `${formatFileSize(progress)} of ${formatFileSize(total)}`;
};

export const formatDateFrom = (date: string): string =>
  Moment.utc(date).local().fromNow();

export const formatDate = (date: string): string =>
  Moment.utc(date).local().format("MMM D, YYYY h:mm A");

export const localNumber = (data: number): string =>
  data.toLocaleString("en-IN");

export const formatFileName = (fileName: string): string => {
  return fileName + "_" + Moment(Date.now()).format("MMM_D_YYYY") + ".csv";
};

export const formatStatusForDisplay = (status?: string | null) =>
  status ? status.replace(/_/g, " ").toUpperCase() : "Not Initiated";

/**
 * @description NOTE: "numerator" is "number | undefined" because in most cases numerator is
 *                     loaded dynamically in components,
 *                     Default is 0.
 * @param {string} date
 * @returns {string} divides 2 numbers and returns ceil of it.
 */
export const divideAndCeil = (
  numerator: number | undefined,
  denominator: number
): number => Math.ceil((numerator || 0) / denominator);
export const safeParseJson = (data: any) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return {}; //return an empty object.
  }
};

export const exportToCsv = (filename: string, rows: object[]) => {
  if (!rows || !rows.length) {
    return;
  }
  const separator = ",";
  const keys = Object.keys(rows[0]);
  const csvContent =
    keys.join(separator) +
    "\n" +
    rows
      .map((row) => {
        return keys
          .map((k) => {
            let cell = row[k] === null || row[k] === undefined ? "" : row[k];
            cell =
              cell instanceof Date
                ? cell.toLocaleString()
                : cell.toString().replace(/"/g, '""');
            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          })
          .join(separator);
      })
      .join("\n");
  downloadFile(csvContent, filename);
};

export const downloadFile = (csvContent: any, filename: string) => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export enum UserPermissions {
  viewProspectList = "view_prospect_list",
  viewDataSourceList = "view_datasource_list",
  viewUploadedFiles = "view_uploaded_files",
  viewProspectDetail = "view_prospect_detail",

  createDataSource = "create_datasource",
  createProspect = "create_prospect",
  uploadFile = "upload_file",

  downloadFile = "download_file",
  downloadFailedRecord = "download_failed_record",

  //Phase-2
  CreateAnchor = "create_anchor",
  ViewAnchorList = "view_anchor_list",
  PayBills = "upload_bills",
  ViewBills = "view_bills",
  ViewEligibilityList = "view_eligibility_list",
  ViewUserActivity = "view_user_activity",
  ViewNotifications = "view_notifications"
}

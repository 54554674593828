import { State } from "../state/index";
import {
  EligibilityDetails,
  ApplicantDetails,
  EligibilityAmountDetails,
  CurrentBankAccountData,
  LoanCalculationResponse
} from "../../services/eligibilityService/EligibilityService.type";
import { EligibilityListFilterType } from "../../services/eligibilityService/LoanStatus.type";

export type EligibilityDetailsState = Omit<
  EligibilityDetails,
  "applicantDetails" | "eligibilityAmountDetails"
> & {
  bankAccount: CurrentBankAccountData;
  applicantDetails: ApplicantDetails;
  eligibilityAmountDetails: EligibilityAmountDetails;

  manualReason: string | null;
};

export interface DropdownOptions {
  loading?: boolean;
  data: string[];
}

export interface EligibilityState extends State {
  eligibilityDetails?: EligibilityDetailsState;
  filterStatus: EligibilityListFilterType;
  searchText: string | undefined;
  instantCalculations?: LoanCalculationResponse;
}

export enum LoanCalculationStatus {
  IN_PROGRESS = "IN-PROGRESS",
  COMPLETED = "COMPLETED",
  STARTED = "STARTED",
  FAILED = "FAILED"
}

import ApiServiceBase from "../ApiServiceBase";
import ApiService from "../ApiService";
import { ServiceType } from "../ServiceType.data";

import {
  EligibilityListResponse,
  EligibilityListPayload,
  ManualLoanAmountData,
  ManualLoanAmountDataResponse,
  DropdownOptionsResponse,
  UpdateAccountCreationStatus,
  AcceptRejectReceivables,
  UpdateReceivableAmount,
  OpportunityLostPayload,
  KYCOnHoldPayload,
  KYCFailurePayload,
  // LoanCalculationResponse,
  ResponseWithMessageData,
  RejectLoan,
  GstFile,
  ApprovalResponse,
  LoanResponse,
  DocType,
  ResponseWithPresignedUrlData
} from "./EligibilityService.type";
import { QueryPath } from "../../services/QueryPath.data";
import { ResponseWithStatus } from "./LoanStatus.type";

export class EligibilityListService {
  public uploadGstrConfig: QueryPath = { route: ["upload-document"] };
  public deleteGstrConfig: QueryPath = { route: ["delete-gst-doc"] };

  public getEligibilityList(
    data?: EligibilityListPayload
  ): EligibilityListResponse | Promise<EligibilityListResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.get({
      route: ["filter-loan-request"],
      query: { ...data }
    });
  }
  public getIndustries():
    | DropdownOptionsResponse
    | Promise<DropdownOptionsResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.Anchor);

    return service.get({ route: ["industry-type"] });
  }
  public getSubIndustries(
    industry: string
  ): DropdownOptionsResponse | Promise<DropdownOptionsResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.Anchor);

    return service.get({ route: ["sub-industries"], query: { industry } });
  }

  public eligibilityDetails(eligibilityId: string) {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.get({ query: { eligibilityId } });
  }

  public triggerManualFileVerification(eligibilityId: string) {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);

    return service.post(
      { route: ["manual-file-verification"] },
      { eligibilityId }
    );
  }

  public manualLoanCalculation(
    data: ManualLoanAmountData
  ): Promise<ManualLoanAmountDataResponse> | ManualLoanAmountDataResponse {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.post({ route: ["calculate-loan"] }, data);
  }

  public updateEligibilityStatus(
    payload: OpportunityLostPayload | KYCOnHoldPayload | KYCFailurePayload
  ): Promise<ResponseWithStatus> | ResponseWithStatus {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.post({ route: ["status"] }, payload);
  }

  public updateAccountCreationStatus(
    data: UpdateAccountCreationStatus
  ): Promise<ResponseWithStatus> | ResponseWithStatus {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.post({ route: ["disbursal-bank-account", "status"] }, data);
  }

  public acceptRejectReceivables(
    data: AcceptRejectReceivables
  ): ResponseWithStatus | Promise<ResponseWithStatus> {
    const service: ApiServiceBase = new ApiService(ServiceType.Anchor);

    return service.post({ route: ["update-receivables-status"] }, data);
  }

  public updateReceivableAmount(
    data: UpdateReceivableAmount
  ): ResponseWithStatus | Promise<ResponseWithStatus> {
    const service: ApiServiceBase = new ApiService(ServiceType.Anchor);
    return service.post(
      { route: ["update-receivables-status"] },
      { ...data, isUpdate: true }
    );
  }

  public getInstanceLoanCalculation(eligibilityId: string): ResponseWithMessageData<any> | Promise<ResponseWithMessageData<any>>{
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.get(
      {
        route: ['cp-admin-flow', `${eligibilityId}`, 'loan-calculation-data'],
      }
    );
  }

  public getLoanCalculation(eligibilityId: string): ResponseWithMessageData<LoanResponse> | Promise<ResponseWithMessageData<LoanResponse>>{
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.get(
      {
        route: ['cp-admin-flow', `${eligibilityId}`, 'loan-calculation-data'],
      }
    );
  }

  public rejectLoanRequest(data: RejectLoan): ResponseWithMessageData<any> | Promise<ResponseWithMessageData<any>> {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.post(
      {
        route: ['cp-admin-flow', `${data.data.eligibilityId}`, 'reject'],
      }, {comment: data.data.comment}
    );
  }

  public downloadGstFileRequest(data: GstFile): ResponseWithMessageData<any> | Promise<ResponseWithMessageData<any>> {
    const service: ApiServiceBase = new ApiService(ServiceType.Reports);
    return service.get(
      {
        route: ['manual-calculation', `${data.gstValue}` + '-report', `${data.gstin}`],
        query: { eligibility_id : `${data.eligibilityId}` }
      }
    );
  }

  public presignedDocsRequest(data: DocType): ResponseWithPresignedUrlData<any> | Promise<ResponseWithPresignedUrlData<any>> {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.get(
      {
        route: ['cp-admin-flow', `${data.eligibilityId}`, 'presigned-doc-urls'],
        query: { docs_type : `${data.docTypeValue}` }
      }
    );
  }

  public approveLoanRequest(data: LoanResponse): ResponseWithMessageData<LoanResponse> | Promise<ResponseWithMessageData<LoanResponse>> {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.post(
      {
        route: ['cp-admin-flow', `${data.eligibilityId}`, 'loan-calculation-data'],
      },
        {
          loanAmount: data.loanAmount,
          processingFees: data.processingFees,
          interestRate: data.interestRate,
          duration: data.duration,
          comments: data.comments,
        }
    );
  }

  public approveKYCDocsRequest(eligibilityId: string): ResponseWithMessageData<ApprovalResponse> | Promise<ResponseWithMessageData<ApprovalResponse>> {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.post(
      {
        route: ['cp-admin-flow', `${eligibilityId}`, 'submit-kyc-docs'],
      }, {}
    );
  }

  public approveLoanDocsRequest(eligibilityId: string): ResponseWithMessageData<ApprovalResponse> | Promise<ResponseWithMessageData<ApprovalResponse>> {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.post(
      {
        route: ['cp-admin-flow', `${eligibilityId}`, 'submit-loan-docs'],
      }, {}
    );
  }
}

const eligibilityListService = new EligibilityListService();
export default eligibilityListService;
